<template>
  <a-spin :spinning="processing">
    <a-form
      :form="form"
      :layout="myFormLayout"
      id="add_form_form"
      name="add_form_form"
    >
      <h1 class="title">Messages communications :</h1>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col :xs="24" :lg="14" :span="13">
          <a-form-item label="Dossier demande inscription:">
            <vue-editor
              v-model="messages_communications.demande_inscription"
            ></vue-editor>
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="14" :span="13">
          <a-form-item label="Dossier demande ouverture:">
            <vue-editor
              v-model="messages_communications.demande_ouverture"
            ></vue-editor>
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="14" :span="13">
          <a-form-item label="Dossier demande transfert:">
            <vue-editor
              v-model="messages_communications.demande_transfert"
            ></vue-editor>
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="14" :span="13">
          <a-form-item label="Dossier license remplacement:">
            <vue-editor v-model="messages_communications.license_remplacement">
            </vue-editor>
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="14" :span="13">
          <a-form-item label="Reglement interieur:">
            <vue-editor v-model="messages_communications.reglement_interieur">
            </vue-editor>
          </a-form-item>
        </a-col>

      </a-row>
      <a-row>
        <a-col :offset="2" :span="2">
          <a-form-item v-bind="tailFormItemLayout">
            <a-button
              :loading="processing"
              @click="handleCreate"
              htmlType="submit"
              type="primary"
              >Sauvergarder
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
import { mapActions, mapState } from "vuex";
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 8,
    },
  },
};

import { VueEditor } from "vue2-editor";
export default {
  name: "MessageCommunicationIndex",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created() {
    this.fetchData();
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      processing: false,
      myFormLayout: "vertical",
      tailFormItemLayout,
      rawHtml: "<b>Two</b>",
    };
  },
  computed: {
    ...mapState({
      messages_communications: (state) =>
        state.messages_communications.messages_communications,
    }),
  },
  methods: {
    handleCreate() {
      this.startProcessing();
      this.update({ ...this.messages_communications })
        .then(() => {
          this.getData();
          this.$_showSuccessMessage();
        })
        .catch(() => {
          this.$_throwAnError();
        })
        .finally(() => this.stopProcessing());
    },
    getData() {
      this.startProcessing();
      this.fetchData().then(() => this.stopProcessing());
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    fillTheForm() {
      if (this.messages_communications) {
        this.$nextTick(() =>
          this.form.setFieldsValue(this.messages_communications)
        );
      }
    },
    ...mapActions({
      fetchData: "fetchMessageCommunication",
      update: "updateMessageCommunication",
    }),
  },
};
</script>
