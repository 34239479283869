var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.processing } },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            layout: _vm.myFormLayout,
            id: "add_form_form",
            name: "add_form_form"
          }
        },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v("Messages communications :")
          ]),
          _c(
            "a-row",
            {
              attrs: { type: "flex", justify: "space-around", align: "middle" }
            },
            [
              _c(
                "a-col",
                { attrs: { xs: 24, lg: 14, span: 13 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "Dossier demande inscription:" } },
                    [
                      _c("vue-editor", {
                        model: {
                          value:
                            _vm.messages_communications.demande_inscription,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.messages_communications,
                              "demande_inscription",
                              $$v
                            )
                          },
                          expression:
                            "messages_communications.demande_inscription"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { xs: 24, lg: 14, span: 13 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "Dossier demande ouverture:" } },
                    [
                      _c("vue-editor", {
                        model: {
                          value: _vm.messages_communications.demande_ouverture,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.messages_communications,
                              "demande_ouverture",
                              $$v
                            )
                          },
                          expression:
                            "messages_communications.demande_ouverture"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { xs: 24, lg: 14, span: 13 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "Dossier demande transfert:" } },
                    [
                      _c("vue-editor", {
                        model: {
                          value: _vm.messages_communications.demande_transfert,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.messages_communications,
                              "demande_transfert",
                              $$v
                            )
                          },
                          expression:
                            "messages_communications.demande_transfert"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { xs: 24, lg: 14, span: 13 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "Dossier license remplacement:" } },
                    [
                      _c("vue-editor", {
                        model: {
                          value:
                            _vm.messages_communications.license_remplacement,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.messages_communications,
                              "license_remplacement",
                              $$v
                            )
                          },
                          expression:
                            "messages_communications.license_remplacement"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { xs: 24, lg: 14, span: 13 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "Reglement interieur:" } },
                    [
                      _c("vue-editor", {
                        model: {
                          value:
                            _vm.messages_communications.reglement_interieur,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.messages_communications,
                              "reglement_interieur",
                              $$v
                            )
                          },
                          expression:
                            "messages_communications.reglement_interieur"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { offset: 2, span: 2 } },
                [
                  _c(
                    "a-form-item",
                    _vm._b({}, "a-form-item", _vm.tailFormItemLayout, false),
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.processing,
                            htmlType: "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleCreate }
                        },
                        [_vm._v("Sauvergarder\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }